import React from 'react';
import "../styles/Iframe.css"

const Iframe = () => {
    return (
        <div className="iframe">
            <iframe src="https://ignitelines.vercel.app/" frameborder="0"
                marginheight="0"
                marginwidth="0"
                width="100%"
                height="100%"
                scrolling="auto">
            </iframe>
        </div>
    )
}

export default Iframe;