import { createSlice } from "@reduxjs/toolkit";

export const OrderInfoSlice = createSlice({
    name: "order",
    initialState: {
        order: null,

    },

    reducers: {
        Get_OrderInfo: (state, action) => {
            state.order = action.payload;
        },

    },
});

export const {
    Get_OrderInfo,
} = OrderInfoSlice.actions;



// Selector to get dispatched Data
export const selectOrderInfo = (state) => state.order.order;

export default OrderInfoSlice.reducer;
