import { createSlice } from "@reduxjs/toolkit";

export const recentlyviewSlice = createSlice({
  name: "recentlyview",
  initialState: {
    recentlyview: [],
  },

  reducers: {
    Add_To_Recent: (state, action) => {
      state.recentlyview = [...state.recentlyview, action.payload];
    },

    Remove_From_Recent: (state, action) => {
      return {
        ...state,
        recentlyview: state.recentlyview.filter(
          (cartItem) => cartItem.id !== action.payload.id
        ),
      };
    },

    Clear_Recent: (state, action) => {
      state.recentlyview = [];
    },
  },
});

export const { Add_To_Recent, Remove_From_Recent, Clear_Recent } =
  recentlyviewSlice.actions;

export const selectRecent = (state) => state.recentlyview.recentlyview;

export default recentlyviewSlice.reducer;
