import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { persistStore } from "redux-persist";
import Store from "./Redux/Store/Store";
import { ThemeProvider, createTheme } from '@mui/material';

const persistor = persistStore(Store);
const theme = createTheme({
  palette: {
    primary: {
      main: "#39AD8E"
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <HttpsRedirect>  */}
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
        {/* </HttpsRedirect> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
