import { createSlice } from "@reduxjs/toolkit";

export const favouriteSlice = createSlice({
  name: "favourite",
  initialState: {
    favourite: [],
    displayModal: false,
  },

  reducers: {
    Add_To_Favourite: (state, action) => {
      state.favourite = [...state.favourite, action.payload];
      // const itemIndex = state.favourite.findIndex(
      //   (item) => item.id === action.payload.id
      // );

      // if (itemIndex) {
      //   state.displayModal = true;
      // } else {
      //   const tempProduct = { ...action.payload };
      //   state.favourite.push(tempProduct);
      // }
      // if (itemIndex >= 0) {
      //   state.displayModal = true;
      // } else {
      //   const tempProduct = [{ ...action.payload, displayModal: true }];
      //   state.favourite.push(tempProduct);
      // }
    },

    Remove_From_Favourite: (state, action) => {
      return {
        ...state,
        favourite: state.favourite.filter(
          (favouriteItem) => favouriteItem.id !== action.payload.id
        ),
      };
    },

    Clear_Favourite: (state, action) => {
      state.favourite = [];
    },
  },
});

export const { Add_To_Favourite, Remove_From_Favourite, Clear_Favourite } =
  favouriteSlice.actions;

export const selectFavourite = (state) => state.favourite.favourite;

export default favouriteSlice.reducer;
