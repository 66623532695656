import React, { useState, useEffect } from 'react'
import AccountSideBar from '../Components/AccountSideBar';
import Header from '../Components/Header';
import { Form, Progress, Input, Select, Modal } from "antd";
import MobileHomeHeader from '../Components/MobileHomeHeader';
import HeaderMobile from '../Components/HeaderMobile';
import { useSelector } from 'react-redux';
import { selectUser } from '../Redux/Slices/UserSlice';
import { Localhost } from '../ConnectionUrls/Connections';
import axios from 'axios';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MaterialTable from "@material-table/core";
const countryList = require("country-list");

const MyOrders = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const [userQuotations, setuserQuotations] = useState([]);
    const [showSpinner, setshowSpinner] = useState(true);

    useEffect(() => {
        setshowSpinner(true);
        const GetQuottesById = () => {
            axios
                .get(`${Localhost}/api/quotes/${user.userid}`, {
                    headers: {
                        "auth-token": `${user.token}`,
                    },
                })
                .then((orders) => {
                    setshowSpinner(false);
                    const UsersQuotations = orders.data.userOrders_;
                    setuserQuotations(UsersQuotations);
                    // console.log(orders);
                })
                .catch((err) => {
                    // alert(err);
                });
        };
        GetQuottesById();
    }, []);



    return (
        <>
            <Header className='sticky top-0 z-50 items-center px-4 shadow-md bg-white hidden lg:flex' />
            {/* <MobileHomeHeader RemoveSearch={true} /> */}
            <HeaderMobile className='MobileHead' headerName='My Quotes' onClick={() => {
                navigate(-1);
            }} />
            <div className=' bg-gray-100'>
                <div className=' grid grid-cols-9 h-screen  gap-2 bg-gray-100 '>
                    <AccountSideBar />
                    <div className='shadow-sm col-span-9 lg:col-span-7  '>
                        <div className='hidden lg:block'>
                            {/* <MaterialTable /> */}
                            {/* <MaterialTable
                            columns={[
                                    {
                                        title: "Name",
                                        field: "productName"
                                    },
                                    {
                                        title:"Shipment Data",
                                        field:"ShipmentDate"
                                    },
                                    {
                                        title:"purchaseVolume",


                                    }
                            ]}
/> */}
                            {/* <h1 className='text-lg'>Personal Information</h1> */}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default MyOrders;