import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    userInfo1: null,
    userInfo2: null,
    GoogleUser: null,
  },

  reducers: {
    signupUserInfo: (state, action) => {
      state.user = action.payload;
    },
    SignOut: (state, action) => {
      state.user = null;
    },
    ClearUserInfo: (state, action) => {
      state.userInfo1 = null;
    },
    SignIn: (state, action) => {
      state.user = action.payload;
    },
    userInfo1: (state, action) => {
      state.userInfo1 = action.payload;
    },
    userInfo2: (state, action) => {
      state.userInfo2 = action.payload;
    },

    GoogleUser: (state, action) => {
      state.GoogleUser = action.payload;
    },
  },
});

export const {
  signupUserInfo,
  SignOut,
  SignIn,
  userInfo1,
  userInfo2,
  GoogleUser,
  ClearUserInfo,
} = userSlice.actions;

// Selector to get dispatched Data
export const selectUser = (state) => state.user.user;
export const selectUserInfo1 = (state) => state.user.userInfo1;
export const selectUserInfo2 = (state) => state.user.userInfo2;
export const selectGoogleUser = (state) => state.user.GoogleUser;
export default userSlice.reducer;
