import React, { useState, useEffect } from 'react'
import AccountSideBar from '../Components/AccountSideBar';
import Header from '../Components/Header';
import { Form, Progress, Input, Select, Modal } from "antd";
import MobileHomeHeader from '../Components/MobileHomeHeader';
import HeaderMobile from '../Components/HeaderMobile';
import { useSelector } from 'react-redux';
import { selectUser } from '../Redux/Slices/UserSlice';
import { Localhost } from '../ConnectionUrls/Connections';
import axios from 'axios';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const countryList = require("country-list");

const PersonalInformation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [position, setposition] = useState("");
    const [companywebsite, setcompanywebsite] = useState("");
    const [phone, setphone] = useState("");
    const [compname, setcompname] = useState("");
    const Clist = countryList.getNames();
    const [countyData, setcountyData] = useState("");

    const [userinformation, setuserInformation] = useState({});
    const [userPersonalInformation, setuserPersonalInformation] = useState({});
    const [getPhone, setgetPhone] = useState({});


    useEffect(() => {
        const Get_User_Infromation = () => {
            axios
                .get(`${Localhost}/userdata/userInformation/${user.userid}`)
                .then((userInfo) => {
                    if (userInfo) {
                        const userdata = userInfo.data.userInfromation;
                        console.log(userdata);
                        setuserInformation(userdata);
                        setuserPersonalInformation(userdata.usercustomdatum);
                    }
                });
        };
        Get_User_Infromation();
    }, []);

    var country;
    const GetCountryChange = (value) => {
        country = value;
        setcountyData(country);
    };

    const BusinessTypeList = [
        "Farmer",
        "Accountant",
        "Business Man",
        "Student",
        "Researcher",
        "Software developer",
        "Banker",
    ];

    const NumberEmployee = [
        "10+",
        "20+",
        "30+",
        "40+",
        "50+",
        "60+",
        "70+",
        "80+",
        "90+",
        "100+",
    ];
    const BusinessType = BusinessTypeList;
    const NumberofEmployes = NumberEmployee;
    const [businessType, setbusinessType] = useState("");
    const [employeesNums, setemployeesNums] = useState("");

    const HandleBusinessTypeChange = (value) => {
        setbusinessType(value);
    };
    const HandleemployeesNumsChange = (value) => {
        setemployeesNums(value);
    };

    return (
        <>
            <Header className='sticky top-0 z-50 items-center px-4 shadow-md bg-white hidden lg:flex' />
            {/* <MobileHomeHeader RemoveSearch={true} /> */}
            <HeaderMobile className='MobileHead' headerName='Personal Profile' onClick={() => {
                navigate(-1);
            }} />
            <div className=' bg-gray-100'>
                <div className=' grid grid-cols-9 h-screen  gap-2 bg-gray-100 '>
                    <AccountSideBar />
                    <div className='shadow-sm col-span-9 lg:col-span-7  rounded-sm bg-white '>
                        <div className=' p-4 shadow-sm hidden lg:block '>
                            <h1 className='text-lg'>Personal Information</h1>
                        </div>
                        <div className=' p-5 '>
                            <Form layout="vertical">
                                <Form.Item label="First Name">
                                    <Input
                                        defaultValue="perry"
                                        disabled
                                        placeholder="First Name"
                                        style={{ padding: 5 }}
                                        value={userPersonalInformation.fname}
                                        onChange={(e) => {
                                            setfname(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Last Name">
                                    <Input
                                        disabled
                                        placeholder="Last Name"
                                        style={{ padding: 5 }}
                                        value={userPersonalInformation.lname}
                                        onChange={(e) => {
                                            setlname(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Company name">
                                    <Input
                                        disabled
                                        placeholder="Enter Email"
                                        style={{ padding: 5 }}
                                        value={userPersonalInformation.compname}
                                        onChange={(e) => {
                                            setcompname(e.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item label="Company Country">
                                    <Select
                                        placeholder={userPersonalInformation.compcntry}
                                        onChange={GetCountryChange}
                                        disabled
                                    >
                                        {Clist.map((countrylist) => {
                                            return (
                                                <Select.Option
                                                    key={countrylist}
                                                    placeholder="Company Country"
                                                    value={userPersonalInformation.compcntry}
                                                // value={countrylist}
                                                >
                                                    {countrylist}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Business Type"
                                    onChange={HandleBusinessTypeChange}
                                >
                                    <Select placeholder="Software Developer" disabled  >
                                        {BusinessType.map((businessList) => {
                                            return (
                                                <Select.Option
                                                    key={businessList}
                                                    placeholder="Business Type"
                                                    value={businessList}
                                                >
                                                    {businessList}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Your position">
                                    <Input
                                        disabled
                                        placeholder="Enter position name"
                                        style={{ padding: 5 }}
                                        value={userPersonalInformation.pos}
                                        onChange={(e) => {
                                            setposition(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Number of Employees">
                                    <Select
                                        disabled
                                        placeholder={userPersonalInformation.noofstaff}
                                        onChange={HandleemployeesNumsChange}
                                    >
                                        {NumberofEmployes.map((numberOfemployees) => {
                                            return (
                                                <Select.Option
                                                    key={numberOfemployees}
                                                    placeholder="Business Type"
                                                    value={userPersonalInformation.noofstaff}
                                                >
                                                    {numberOfemployees}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Phone Number">
                                    <Input
                                        disabled
                                        placeholder="Enter Phone Number"
                                        style={{ padding: 5 }}
                                        value={userPersonalInformation.phone}
                                        onChange={(e) => {
                                            setphone(e.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item label="Company website">
                                    <Input
                                        disabled
                                        placeholder="Enter website url"
                                        style={{ padding: 5 }}
                                        value={userPersonalInformation.compweb}
                                        onChange={(e) => {
                                            setcompanywebsite(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <div className=' w-full'>
                                    <Button sx={{
                                        width: "100%",
                                        backgroundColor: "#39AD8E !important",
                                        color: "white",
                                        // borderRadius: 0,
                                        width: "100%"

                                    }} onClick={() => {
                                        navigate("/account/update-profile");
                                    }}>Update Information</Button>
                                </div>
                            </Form>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalInformation;