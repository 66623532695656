import React, { useEffect, useState } from 'react';
import { Form, Progress, Input, Select, Modal } from "antd";
import ContinueBoard from '../Components/ContinueBoard';
import HeaderMobile from '../Components/HeaderMobile';
import logo2 from "../images/logo2.png";
import LinerSpinner from '../Components/Linearspinner';
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from '../Redux/Slices/UserSlice';
import axios from 'axios';
import { Localhost } from '../ConnectionUrls/Connections';
import MobileHomeHeader from '../Components/MobileHomeHeader';
import { useNavigate } from 'react-router-dom';
const countryList = require("country-list");


const UserInformation = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    // const [showLinearLoading, setshowLinearLoading] = useState(false);
    const Clist = countryList.getNames();
    const [showLinearLoading, setshowLinearLoading] = useState(false);
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [position, setposition] = useState("");
    const [companywebsite, setcompanywebsite] = useState("");
    const [phone, setphone] = useState("");
    const [compname, setcompname] = useState("");
    const [businessType, setbusinessType] = useState("");
    const [employeesNums, setemployeesNums] = useState("");
    const [countyData, setcountyData] = useState("");
    var country;
    const GetCountryChange = (value) => {
        country = value;
        setcountyData(country);
    };

    // const countryList:string[] = countryListData();
    const BusinessType = ["Farmer",
        "Accountant",
        "Business Man",
        "Student",
        "Researcher",
        "Software developer",
        "Banker",]
    const NumberEmployee = [
        "10+",
        "20+",
        "30+",
        "40+",
        "50+",
        "60+",
        "70+",
        "80+",
        "90+",
        "100+",
    ]

    const ContinueNext = () => {
        setshowLinearLoading(true);

        const userCustomInfo = {
            fname: fname,
            lname: lname,
            compcntry: countyData,
            bstypename: businessType,
            pos: position,
            compweb: companywebsite,
            noofstaff: employeesNums,
            phone: phone,
            compname: compname,
        };

        axios
            .post(`${Localhost}/userdata/user_custom_data/${user.userid}`, userCustomInfo)
            .then((User) => {
                const customUser = User.data.customUser;
                if (customUser) {
                    navigate("/your-intrest")
                    setshowLinearLoading(false);
                }
            })
            .catch((err) => {
                setshowLinearLoading(false);

                Modal.error({
                    title: "Failed",
                    content: "Pls Try again",
                });
            });
    };
    return (
        <>
            {/* <MobileHomeHeader RemoveSearch={true} /> */}
            <HeaderMobile headerName='Personal Information' className='MobileHead' onClick={() => {
                navigate(-1);
            }} />
            {showLinearLoading && <LinerSpinner />}
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 '>
                <div>
                    <div className='hidden lg:block'>
                        <img src={logo2} height="100" width="100" />
                    </div>
                    <div className='grid place-items-center'>
                        <div className='bg-white px-5 lg:px-32 lg:pt-14'>
                            <div className=' py-5'>
                                <Progress
                                    percent={50}
                                    status="active"
                                    showInfo={false}
                                    strokeColor="#009F7F"
                                />
                            </div>
                            <div>
                                <h1 className='text-xl lg:text-3xl'>Hi there, Welcome To Ignitelines</h1>
                                <h4 className='py-2' style={{ color: "#7A8389" }}>
                                    Please give us brief information about you and your company.This
                                    information will be used to help us determine your account role
                                    and give you a better experice and Ingnitelines
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='bg-gray-100'>
                    <div className='grid place-items-center'>
                        <div className='shadow-sm bg-white w-full md:w-9/12 lg:w-2/3 p-5 lg:p-8 my-4 lg:my-12 rounded-md '>
                            <Form layout="vertical">
                                <Form.Item label="First Name">
                                    <Input
                                        placeholder="First Name"
                                        style={{ padding: 5 }}
                                        value={fname}
                                        onChange={(e) => {
                                            setfname(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Last Name">
                                    {/* <h3>{countyData}</h3> */}
                                    <Input
                                        placeholder="Last Name"
                                        style={{ padding: 5 }}
                                        value={lname}
                                        onChange={(e) => {
                                            setlname(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Company Name">
                                    <Input
                                        placeholder="Company Name"
                                        style={{ padding: 5 }}
                                        value={compname}
                                        onChange={(e) => {
                                            setcompname(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Company Country">
                                    <Select
                                        placeholder="Company Country"
                                        onChange={GetCountryChange}
                                    >
                                        {Clist.map((countrylist) => {
                                            return (
                                                <Select.Option
                                                    key={countrylist}
                                                    placeholder="Company Country"
                                                    value={countrylist}
                                                >
                                                    {countrylist}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Business Type">
                                    <Select placeholder="Select Business type" onChange={(value) => {
                                        setbusinessType(value);
                                    }}>
                                        {BusinessType.map((businessList) => {
                                            return (
                                                <Select.Option
                                                    key={businessList}
                                                    placeholder="Business Type"
                                                    value={businessList}
                                                >
                                                    {businessList}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Your Position">
                                    <Input
                                        placeholder="Enter position name"
                                        style={{ padding: 5 }}
                                        value={position}
                                        onChange={(e) => {
                                            setposition(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Number of Employees">
                                    <Select
                                        placeholder="Select Number of Employees"
                                        onChange={(value) => {
                                            setemployeesNums(value);
                                        }}
                                    >
                                        {NumberEmployee.map((numberOfemployees) => {
                                            return (
                                                <Select.Option
                                                    key={numberOfemployees}
                                                    placeholder="Business Type"
                                                    value={numberOfemployees}
                                                >
                                                    {numberOfemployees}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Phone Number">
                                    <Input
                                        placeholder="Enter Phone Number"
                                        style={{ padding: 5 }}
                                        value={phone}
                                        onChange={(e) => {
                                            setphone(e.target.value);
                                        }}
                                        type="number"
                                    />
                                </Form.Item>
                                <Form.Item label="Company website">
                                    <Input
                                        placeholder="Enter website url"
                                        style={{ padding: 5 }}
                                        value={companywebsite}
                                        onChange={(e) => {
                                            setcompanywebsite(e.target.value);
                                        }}
                                    />
                                </Form.Item>

                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <ContinueBoard
                // ShowPrevious={true}
                Previous={() => {

                }} disabled={
                    !compname ||
                    !fname ||
                    !lname ||
                    !position ||
                    !companywebsite ||
                    !phone
                } Continue={ContinueNext} />
        </>
    )
}

export default UserInformation;