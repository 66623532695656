
import { Divider, Form, Input, Modal, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import {
   FacebookOutlined,
   GoogleOutlined,
   CheckSquareOutlined,
   EyeInvisibleOutlined,
   EyeOutlined,
} from "@ant-design/icons";
import HeaderMobile from '../Components/HeaderMobile';
import logo2 from "../images/logo2.png";
import axios from 'axios';
import { Localhost } from '../ConnectionUrls/Connections';
import LinerSpinner from '../Components/Linearspinner';
import { useDispatch } from "react-redux";
import { signupUserInfo } from '../Redux/Slices/UserSlice';
import { Button, CircularProgress, IconButton } from '@mui/material';
import style from "../styles/signup.module.css";
import SnackBar from '../Components/SnackBar';
import MobileHomeHeader from '../Components/MobileHomeHeader';
import { useNavigate } from "react-router-dom";
const countryList = require("country-list");


const SignUp = () => {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   const navigate = useNavigate();
   const [email, setemail] = useState("");
   const [pass, setpass] = useState("");
   // const [showLinearLoading, setshowLinearLoading] = useState(false);
   const [showSpinner, setshowSpinner] = useState(false);
   const [countyData, setcountyData] = useState("");
   const [shownackBar, setshownackBar] = useState(false);
   const [showLoading, setshowLoading] = useState(false);
   const [showButton, setshowButton] = useState(true);

   // const [countyData, setcountyData] = useState("");
   const [showeye, setshoweye] = useState(true);
   var country;
   // const GetCountryChange = (value) => {
   //    country = value;
   //    setcountyData(country);
   // };
   const dispatch = useDispatch();

   const CountryArray = countryList.getNames();

   const signupUser = () => {
      setshowButton(false);
      setshowLoading(true);
      const signup_data = {
         email,
         pass,
         country: countyData,
      };

      axios
         .post(`${Localhost}/userdata/signup`, signup_data)
         .then((user) => {
            const createduser = user.data;
            if (createduser) {
               navigate("/personal-information");
               console.log(createduser);
               dispatch(
                  signupUserInfo({
                     userid: createduser.id,
                     email: createduser.email,
                     token: createduser.token,
                     // tokenid: createduser.token.id,
                  })
               );
            }
         })
         .catch((err) => {
            setshownackBar(true);
            setshowButton(true);
            setshowLoading(false);
            // alert(err)

         });
      // setshownackBar(true);
   }

   return (
      <>
         <MobileHomeHeader RemoveSearch={true} />
         <SnackBar onClose={() => {
            setshownackBar(false);
         }} open={shownackBar} autoHideDuration={5000} message="Invalid Email or Password..Please try again" action="error" />
         {/* <MobileHomeHeader RemoveSearch={true} /> */}

         <div className=' h-screen grid grid-cols-10 bg-gray-100 flex-1'>
            <div className=' col-span-5 hidden lg:block'>
               <div className={style.signupContainer}>
                  <div className=' py-3'>
                     <h1 className=" text-4xl font-semibold text-center text-white ">
                        Africa's largest Global Food and Agriculture Sourcing Hub.
                     </h1>
                     <div className='grid place-items-center pt-4'>
                        <Button
                           style={{
                              backgroundColor: "#F3820B",
                              padding: 10,
                              textTransform: "initial",
                              fontWeight: "bold"
                           }}
                           onClick={() => {
                              navigate("/browse/all");
                           }} variant="contained" className=' bg-[#F3820B]  w-2/5 hover:bg-[#F3820B] text-white font-semibold normal-case p-3 !important'>Explore Our Markets</Button>
                     </div>
                  </div>


                  {/* <div> </div> */}

               </div>
            </div>
            <div className=' col-span-10 lg:col-span-5 bg-white'>
               <div className='grid place-items-center h-screen'>
                  <div className='w-11/12  md:w-2/4  lg:w-3/5 '>
                     <div>
                        <h1 className=' text-2xl lg:text-3xl py-2'>Create an account</h1>
                        <div onClick={() => {
                           navigate("/login")
                        }}>
                           <p className=' cursor-pointer hover:text-[#F3820B] py-2'>or sign in to your account</p>
                        </div>
                     </div>
                     <Form
                        layout="vertical"
                        initialValues={{ remember: true }}
                     // onFinish={onFinish}
                     >
                        <Form.Item label="Country">
                           <Select

                              placeholder="Select Country"
                              //
                              onChange={(e) => {
                                 setcountyData(e)
                              }}
                              // style={{ width: "70%" }}
                              className="percent"
                           >
                              {CountryArray.map((countrylist) => {
                                 return (
                                    <Select.Option
                                       key={countrylist}
                                       placeholder="Company Country"
                                       value={countrylist}
                                       className="percent"
                                    // style={{ padding: 10 }}
                                    >
                                       {countrylist}
                                    </Select.Option>
                                 );
                              })}
                           </Select>
                        </Form.Item>
                        <Form.Item label="Email">
                           <Input
                              // disabled={showLinearLoading}
                              placeholder="Email"
                              style={{ padding: 8 }}
                              value={email}
                              onChange={(e) => {
                                 const email = e.target.value;
                                 setemail(email);
                              }}
                           />
                        </Form.Item>
                        <Form.Item label="Password">
                           <Input
                              type={showeye ? "password" : "text"}
                              // disabled={showLinearLoading}
                              placeholder="Password"
                              style={{ padding: 8 }}
                              value={pass}
                              onChange={(e) => {
                                 const pass = e.target.value;
                                 setpass(pass);
                              }}
                              suffix={showeye ? <IconButton onClick={() => {
                                 setshoweye(!showeye)
                              }} > <EyeInvisibleOutlined style={{ fontSize: 15 }} /></IconButton> : <IconButton onClick={() => {
                                 setshoweye(!showeye)
                              }}

                              >

                                 <EyeOutlined style={{ fontSize: 15 }} />
                              </IconButton>}
                           />
                        </Form.Item>
                        {showLoading && <Button style={{
                           backgroundColor: "#F3820B",
                           padding: 10,
                           textTransform: "initial",
                           fontWeight: "bold"
                        }} variant="contained" className='bg-[#F3820B]  w-full hover:bg-[#F3820B] text-white font-semibold normal-case p-3 '><CircularProgress size={24} style={{
                           color: "white"
                        }} /></Button>}
                        {showButton && <Button style={{
                           backgroundColor: "#F3820B",
                           padding: 10,
                           textTransform: "initial",
                           fontWeight: "bold"
                        }} onClick={signupUser} variant="contained" className='bg-[#F3820B]  w-full hover:bg-[#F3820B] text-white font-semibold normal-case p-3 '>Sign Up</Button>}
                     </Form>
                     <div className="">
                        <Divider plain orientation="center">
                           OR
                        </Divider>
                        <div className=' grid place-items-center'>
                           <div className='flex items-center space-x-3'>
                              <IconButton>
                                 <FacebookOutlined style={{ color: "#3b5998" }} />
                              </IconButton>
                              <IconButton>
                                 <GoogleOutlined style={{
                                    color: "#ea4335",
                                 }} />
                              </IconButton>
                           </div>
                           <p className='text-center'>By creating an account, you agree to Ignitelines’s Terms of Service and Privacy Policy.</p>

                        </div>


                     </div>
                  </div>

               </div>


            </div>
         </div>
      </>
   )
}

export default SignUp;
