import React from 'react'

const TrasactionCards = ({ inFoName, figure }) => {
    return (
        <div className=" bg-white shadow-sm p-10">
            <p>{inFoName}</p>
            <h2>{figure}</h2>
        </div>
    )
}


export default TrasactionCards;