import { createSlice } from "@reduxjs/toolkit";

const RecentlySearchSlice = createSlice({
  name: "recentSearch",
  initialState: {
    recentSearch: [],
  },

  reducers: {
    RecentSearch: (state, action) => {
      state.recentSearch = [...state.recentSearch, action.payload];
    },

    Clear_Recent_: (state, action) => {
      state.recentSearch = [];
    },
  },
});

export const { RecentSearch, Clear_Recent_ } = RecentlySearchSlice.actions;

export const selectRecentSearch = (state) => state.recentSearch.recentSearch;

export default RecentlySearchSlice.reducer;
