import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import ItemList from '../Components/ItemList';
import axios from "axios";
import ItemList from './ItemList';
import { Flagpath, Imagehost, Localhost } from '../ConnectionUrls/Connections';
import { message } from 'antd';
import { LoarderCards } from './LoarderCards';
import { useSelector } from 'react-redux';
import { selectUser } from '../Redux/Slices/UserSlice';
import { useNavigate } from 'react-router-dom';



// interface Props {
//     products: Products[]    // products:[Products]
// }

const ItemsContainer = () => {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const user2 = 1;

    const [GetProducts, setGetProducts] = useState([]);
    const [Spinner, setSpinner] = useState(false);

    const AddToBookMark = () => {

    }

    useEffect(() => {
        setSpinner(true);
        const Get_Product = () => {
            axios
                .get(`${Localhost}/products/`, {
                    // cancelToken: source.token,
                })
                .then((products) => {
                    const Products = products.data;
                    setSpinner(false);
                    setGetProducts(Products.products);
                    // setoffer(Products.tbloffer)
                    // setImages(Products.productsid.tblimage);
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        Get_Product();
    }, []);

    return (
        <div className='px-2 lg:px-12  bg-gray-100'>
            <div className='py-4'>
                <h1 className=' lg:text-xl'>POPULAR ON OUR MARKET</h1>
            </div>
            {Spinner ? <LoarderCards /> :
                <div
                    className='grid grid-cols-2 sm:grid-cols-3  md:grid-cols-4 lg:grid-cols-6 gap-2 lg:gap-3'
                // className='lg:grid grid-cols-2 sm:grid-cols-3  md:grid-cols-4 lg:grid-cols-6 gap-3 flex overflow-x-auto '
                >
                    {GetProducts.slice(0, 12).map((list) => {
                        return (
                            <ItemList
                                clickFavourite={() => {
                                    const payload = {
                                        productName: list.productName,
                                        productSlug: list.productSlug,
                                        productPrice: list.productPrice,
                                        productCountry: list.productCountry,
                                        productCategory: list.productCategory,
                                        productImage: `${Imagehost}/${list.productImage}`,
                                        countryImage: `${Imagehost}/${Flagpath}${list.countryImage}`,
                                        productStatus: list.productStatus,
                                        productDetails: list.productDetails,

                                    }

                                    axios.post(`${Localhost}/savedproduct/savedproduct/${user.userid}`, payload).then((savedproduct) => {
                                        const sproduct = savedproduct.data;
                                        if (sproduct) {
                                            message.success("Product Added to Saved products");
                                        }
                                    }).catch((err) => {
                                        message.error("Product Already Added to Bookmarks")
                                        // alert(err);
                                    })
                                }}
                                key={list.productid} minimumOrder={list.tbloffer.minimumOrder} productImage={`${Imagehost}/${list.productImage}`} productCountry={list.productCountry} onClickMe={() => {
                                    navigate(`/product-details/${list.productSlug}`)

                                }} onClick={() => {
                                    navigate(`/product-details/${list.productSlug}`)
                                }} productName={list.productName} productPrice={list.productPrice} countryImage={`${Imagehost}/${Flagpath}${list.countryImage}`} productStatus={list.productStatus} />
                        )
                    })
                    }


                </div>
            }

            <div className='grid place-items-center py-7'>
                <Button onClick={() => {
                    navigate("/browse/all")
                }} sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    backgroundColor: "#1BA182 !important",
                    color: "white",
                    textTransform: "initial"

                }}>Explore Products</Button>
            </div>
        </div>
    )
}


export default ItemsContainer;

