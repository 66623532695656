import './App.less';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from './Pages/ContactUs';
import Browse from './Pages/Browse';
import Categories from './Pages/Categories';
import ProductDetails from './Pages/ProductDetails';
import Manage from "./Pages/Manage";
import SignUp from "./Pages/SignUp";
import Login from "./Pages/Login";
import UserInformation from './Pages/UserInformation';
import Intrest from "./Pages/Intrest";
import BecomeSupplier from "./Pages/BecomeSupplier";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Invest from "./Pages/Invest";
import GetQuotations from "./Pages/GetQuotations";
import BookmarkedProducts from "./Pages/BookmarkedProducts";
import Search from "./Pages/Search";
import MyOrders from "./Pages/MyOrders";
import MyQuotations from "./Pages/MyQuotations";
import UpdatePersonalInformation from "./Pages/UpdatePersonalInformation";
import PersonalProfile from "./Pages/PersonalInformation";
import RegistrationComplete from "./Pages/RegistrationComplete";
import Account from "./Pages/Account";
import SearchMobile from "./Pages/mobile/SearchMobile";
import MakeAnOrder from "./Pages/MakeAnOrder";
import Services from "./Pages/Services";
import ProductCatsMobile from "./Pages/ProductCatsMobile";
import PersonalInformation from "./Pages/PersonalInformation";
import RecentViewedProducts from './Pages/RecentViewedProducts';
import ChangePassword from "./Pages/ChangePassword";
import Iframe from "./Components/Iframe";

// import MyQuotations from "./Pages/MyQuotations";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Iframe />} />
        {/* <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
        <Route path={`${process.env.PUBLIC_URL}/about-us`} element={<AboutUs />} />
        <Route path={`${process.env.PUBLIC_URL}/contact-us`} element={<ContactUs />} />
        <Route path={`${process.env.PUBLIC_URL}/browse/:productCtgry`} element={<Browse />} />
        <Route path={`${process.env.PUBLIC_URL}/categories/:category`} element={<Categories />} />
        <Route path={`${process.env.PUBLIC_URL}/product-details/:productname`} element={<ProductDetails />} />
        <Route path={`${process.env.PUBLIC_URL}/fulfillment/manage`} element={<Manage />} />
        <Route path={`${process.env.PUBLIC_URL}/sign-up`} element={<SignUp />} />
        <Route path={`${process.env.PUBLIC_URL}/registration/complete`} element={<RegistrationComplete />} />
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route path={`${process.env.PUBLIC_URL}/personal-information`} element={<UserInformation />} />
        <Route path={`${process.env.PUBLIC_URL}/your-intrest`} element={<Intrest />} />
        <Route path={`${process.env.PUBLIC_URL}/become-a-supplier`} element={<BecomeSupplier />} />
        <Route path={`${process.env.PUBLIC_URL}/privacy/policy`} element={<PrivacyPolicy />} />
        <Route path={`${process.env.PUBLIC_URL}/invest-in-us`} element={<Invest />} />
        <Route path={`${process.env.PUBLIC_URL}/get-quotes`} element={<GetQuotations />} />
        <Route path={`${process.env.PUBLIC_URL}/account/favourite-products`} element={<BookmarkedProducts />} />
        <Route path={`${process.env.PUBLIC_URL}/search/:searchValue`} element={<Search />} />
        <Route path={`${process.env.PUBLIC_URL}/account/my-order`} element={<MyOrders />} />
        <Route path={`${process.env.PUBLIC_URL}acount/my-quotations`} element={<MyQuotations />} />
        <Route path={`${process.env.PUBLIC_URL}/account/update-profile`} element={<UpdatePersonalInformation />} />
        <Route path={`${process.env.PUBLIC_URL}/account/personal-profile`} element={<PersonalProfile />} />
        <Route path={`${process.env.PUBLIC_URL}/account`} element={<Account />} />
        <Route path={`${process.env.PUBLIC_URL}/mobile/search`} element={<SearchMobile />} />
        <Route path={`${process.env.PUBLIC_URL}/make-an-order`} element={<MakeAnOrder />} />
        <Route path={`${process.env.PUBLIC_URL}/services`} element={<Services />} />
        <Route path={`${process.env.PUBLIC_URL}/product-categories`} element={<ProductCatsMobile />} />
        <Route path={`${process.env.PUBLIC_URL}/account/personal-information`} element={<PersonalInformation />} />
        <Route path={`${process.env.PUBLIC_URL}/account/recent-viewed-products`} element={<RecentViewedProducts />} />
        <Route path={`${process.env.PUBLIC_URL}/account/change-password`} element={<ChangePassword />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
