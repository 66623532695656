import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import UserReducer from "../Slices/UserSlice";
import recentViewReducer from "../Slices/RecentViewSlice";
import favouriteReducer from "../Slices/FavouriteSlice";
import modalReducer from "../Slices/ModalSlice";
import RecentSearchReducer from "../Slices/RecentSearchSlice";
import unitReducer from "../Slices/PriceUniteSlice";
import OrderInfoReducer from "../Slices/OrderInfoSlice";
import productCatReducer from "../Slices/GetProductCatSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  user: UserReducer,
  recentlyview: recentViewReducer,
  favourite: favouriteReducer,
  Open_Modal: modalReducer,
  recentSearch: RecentSearchReducer,
  unit: unitReducer,
  order: OrderInfoReducer,
  productCat: productCatReducer

});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
});




// import { configureStore } from '@reduxjs/toolkit'
// import { setupListeners } from '@reduxjs/toolkit/query'
// import { combineReducers } from '@reduxjs/toolkit'
// import UserReducer from "../Slices/UserSlice";
// import recentViewReducer from "../Slices/RecentViewSlice";
// import favouriteReducer from "../Slices/FavouriteSlice";
// import modalReducer from "../Slices/ModalSlice";
// import RecentSearchReducer from "../Slices/RecentSearchSlice";
// import unitReducer from "../Slices/PriceUniteSlice";
// import OrderInfoReducer from "../Slices/OrderInfoSlice";
// import storage from 'redux-persist/lib/storage'
// import {
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist'

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const reducers = combineReducers({
//   user: UserReducer,
//   recentlyview: recentViewReducer,
//   favourite: favouriteReducer,
//   Open_Modal: modalReducer,
//   recentSearch: RecentSearchReducer,
//   unit: unitReducer,
//   order: OrderInfoReducer

// });

// const persistedReducer = persistReducer(persistConfig, reducers)

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }).concat(apiSlice.middleware),
// })

// setupListeners(store.dispatch)
// export default store


// const persistedReducer = persistReducer(persistConfig, reducers);

// export default configureStore({
//   reducer: persistedReducer,
// });
