import { createSlice } from "@reduxjs/toolkit";

export const unitSlice = createSlice({
    name: "unit",
    initialState: {
        unit: null,
        
    },

    reducers: {
        Get_Unit: (state, action) => {
            state.unit = action.payload;
        },
       
    },
});

export const {
    Get_Unit,
   
} = unitSlice.actions;

// Selector to get dispatched Data
export const selectUnit = (state) => state.unit.unit;

export default unitSlice.reducer;
