import React, { useState, useEffect } from 'react'
import AccountSideBar from '../Components/AccountSideBar';
import Header from '../Components/Header';
import { Form, Progress, Input, Select, Modal } from "antd";
import MobileHomeHeader from '../Components/MobileHomeHeader';
import HeaderMobile from '../Components/HeaderMobile';
import { useSelector } from 'react-redux';
import { selectUser } from '../Redux/Slices/UserSlice';
import { Localhost } from '../ConnectionUrls/Connections';
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import SnackBar from '../Components/SnackBar';
import SnackBar2 from '../Components/SnackBar';
import { useNavigate } from 'react-router-dom';
const countryList = require("country-list");

const UpdatePersonalInformation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [position, setposition] = useState("");
    const [companywebsite, setcompanywebsite] = useState("");
    const [phone, setphone] = useState("");
    const [compname, setcompname] = useState("");
    const Clist = countryList.getNames();
    const [countyData, setcountyData] = useState("");

    const [userinformation, setuserInformation] = useState({});
    const [userPersonalInformation, setuserPersonalInformation] = useState({});
    const [getPhone, setgetPhone] = useState({});
    const [sbuttton, setsbuttton] = useState(true);
    const [LoadingSpinner, setloaderButton] = useState(false);
    const [showSnackBar, setshowSnackBar] = useState(false);
    const [showErrorsnackBar, setshowErrorsnackBar] = useState(false);


    const UpdateInfromation = () => {
        setloaderButton(true);
        setsbuttton(false);
        const updatePayload = {
            fname: fname,
            lname: lname,
            compcntry: countyData,
            bstypename: businessType,
            pos: position,
            compweb: companywebsite,
            noofstaff: employeesNums,
            phone: phone,
            compname: compname,
        };
        axios
            .put(`${Localhost}/userdata/user_custom_data/${user.userid}`, updatePayload, {
            })
            .then((updateduser) => {
                const updated = updateduser.data.updatedUser;
                if (updated) {
                    // setLoadingSpinner(false);
                    setshowSnackBar(true);
                    setsbuttton(true);
                }
            })
            .catch((err) => {
                // alert(err);
                setloaderButton(false);
                setsbuttton(true);
                setshowErrorsnackBar(true);
            });
    };


    var country;
    const GetCountryChange = (value) => {
        country = value;
        setcountyData(country);
    };

    const BusinessTypeList = [
        "Farmer",
        "Accountant",
        "Business Man",
        "Student",
        "Researcher",
        "Software developer",
        "Banker",
    ];

    const NumberEmployee = [
        "10+",
        "20+",
        "30+",
        "40+",
        "50+",
        "60+",
        "70+",
        "80+",
        "90+",
        "100+",
    ];
    const BusinessType = BusinessTypeList;
    const NumberofEmployes = NumberEmployee;
    const [businessType, setbusinessType] = useState("");
    const [employeesNums, setemployeesNums] = useState("");

    const HandleBusinessTypeChange = (value) => {
        setbusinessType(value);
    };
    const HandleemployeesNumsChange = (value) => {
        setemployeesNums(value);
    };

    return (
        <>
            <SnackBar open={showSnackBar} action="success" message="Profile Updated Successfully" />
            <SnackBar2 autoHideDuration={5000} open={showErrorsnackBar} action="error" message="Profile update Failed." />
            <Header className='sticky top-0 z-50 items-center px-4 shadow-md bg-white hidden lg:flex' />
            {/* <MobileHomeHeader RemoveSearch={true} /> */}
            <HeaderMobile className='MobileHead' headerName='Update Personal Profile' onClick={() => {
                navigate(-1);
            }} />
            <div className='bg-gray-100 grid grid-cols-9'>
                <AccountSideBar />
                <div className='bg-white shadow-sm col-span-9 lg:col-span-7  rounded-sm lg:m-10 '>
                    <div className=' p-4 shadow-sm hidden lg:block '>
                        <h1 className='text-lg'>Personal Information</h1>
                    </div>
                    <div className=' p-5 '>
                        <Form layout="vertical">
                            <Form.Item label="First Name">
                                <Input
                                    defaultValue="perry"
                                    placeholder="First Name"
                                    style={{ padding: 5 }}
                                    value={userPersonalInformation.fname}
                                    onChange={(e) => {
                                        setfname(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Last Name">
                                <Input
                                    placeholder="Last Name"
                                    style={{ padding: 5 }}
                                    value={userPersonalInformation.lname}
                                    onChange={(e) => {
                                        setlname(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Company name">
                                <Input
                                    placeholder="Enter Email"
                                    style={{ padding: 5 }}
                                    value={userPersonalInformation.compname}
                                    onChange={(e) => {
                                        setcompname(e.target.value);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="Company Country">
                                <Select
                                    placeholder={userPersonalInformation.compcntry}
                                    onChange={GetCountryChange}
                                >
                                    {Clist.map((countrylist) => {
                                        return (
                                            <Select.Option
                                                key={countrylist}
                                                placeholder="Company Country"
                                                value={userPersonalInformation.compcntry}
                                            // value={countrylist}
                                            >
                                                {countrylist}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Business Type"
                                onChange={HandleBusinessTypeChange}
                            >
                                <Select placeholder="Software Developer" disabled  >
                                    {BusinessType.map((businessList) => {
                                        return (
                                            <Select.Option
                                                key={businessList}
                                                placeholder="Business Type"
                                                value={businessList}
                                            >
                                                {businessList}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Your position">
                                <Input
                                    placeholder="Enter position name"
                                    style={{ padding: 5 }}
                                    value={userPersonalInformation.pos}
                                    onChange={(e) => {
                                        setposition(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Number of Employees">
                                <Select
                                    placeholder={userPersonalInformation.noofstaff}
                                    onChange={HandleemployeesNumsChange}
                                >
                                    {NumberofEmployes.map((numberOfemployees) => {
                                        return (
                                            <Select.Option
                                                key={numberOfemployees}
                                                placeholder="Business Type"
                                                value={userPersonalInformation.noofstaff}
                                            >
                                                {numberOfemployees}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Phone Number">
                                <Input
                                    // disabled
                                    placeholder="Enter Phone Number"
                                    style={{ padding: 5 }}
                                    value={userPersonalInformation.phone}
                                    onChange={(e) => {
                                        setphone(e.target.value);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="Company website">
                                <Input
                                    // disabled
                                    placeholder="Enter website url"
                                    style={{ padding: 5 }}
                                    value={userPersonalInformation.compweb}
                                    onChange={(e) => {
                                        setcompanywebsite(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <div className=' w-full'>
                                {sbuttton &&
                                    <Button sx={{
                                        width: "100%",
                                        backgroundColor: "#39AD8E !important",
                                        color: "white",
                                        // borderRadius: 0,
                                        width: "100%"

                                    }} onClick={UpdateInfromation}>Update Information</Button>
                                }
                                {LoadingSpinner &&
                                    <Button disabled sx={{
                                        width: "100%",
                                        backgroundColor: "#39AD8E !important",
                                        color: "white",
                                        // borderRadius: 0,
                                        width: "100%"

                                    }} ><CircularProgress size={25} sx={{
                                        color:"white"
                                    }} /></Button>
                                }
                            </div>
                        </Form>

                    </div>

                </div>
            </div>
        </>
    )
}

export default UpdatePersonalInformation;