import { createSlice } from "@reduxjs/toolkit";

export const productCategorySlice = createSlice({
    name: "productCat",
    initialState: {
        productCat: null,

    },

    reducers: {
        Get_Product_Category: (state, action) => {
            state.productCat = action.payload;
        },

    },
});

export const {
    Get_Product_Category
} = productCategorySlice.actions;

// Selector to get dispatched Data
export const selectCategory = (state) => state.productCat.productCat;

export default productCategorySlice.reducer;
