import React from 'react';
import { Button, Drawer } from 'antd';
import { SwipeableDrawer } from '@mui/material';
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles({
    drawerPaper: {
        borderRadius: '20px 20px 0 0'
    }
})

const CustomDrawer = ({
    title,
    placement,
    onClose,
    visible,
    Content,

}) => {
    return (
        <div>
            <Drawer width="80%" title={title} placement={placement} closable={false} onClose={onClose} visible={visible}>
                <Content />
            </Drawer>
        </div>
    )
}

const SwipperpableDrawer = ({ open, onClose, onOpen, Content }) => {
    const classes = useStyles();
    return (
        <SwipeableDrawer classes={{ paper: classes.drawerPaper }} style={{ borderRadius: 10 }} PaperProps={{ borderLeftRadius: 10, borderRightRadius: 10 }}  variant="temporary" anchor="bottom" open={open} onClose={onClose} onOpen={onOpen} >
            <Content />
        </SwipeableDrawer>
    );
}

export { CustomDrawer, SwipperpableDrawer };